import React, { useState, useEffect, useRef, HTMLProps } from 'react';
import styled, { css } from 'styled-components';
import { Player } from '@lottiefiles/react-lottie-player';
import usePosition from '../components/usePosition';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import useCookie from './useCookie';
import TLink from './TLink';

export enum Variant {
  Gradient = 'gradient',
  Vertical = 'vertical',
}
export enum HeadingType {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
}

interface ITitle {
  font?: string;
  desktopsize?: string;
  weight?: string;
  color?: string;
  align?: string;
  mobileSize?: string;
  type?: string;
  heading?: HeadingType;
  variant?: Variant;
  children: React.ReactNode;
}
type CustomITitle = HTMLProps<HTMLDivElement> & ITitle;

export const Section = styled.section`
  color: #3c4356;
  border-radius: 3px;
  @media (max-width: 768px) {
    margin: ${props => props.mobileMargin || '0px'};
    padding: ${props => props.mobilePadding || '25px 5%'};
  }
  @media (min-width: 768px) {
    margin: ${props => props.tabletMargin || '0px'};
    padding: ${props => props.tabletPadding || '60px 0px'};
  }
  @media (min-width: 992px) {
    margin: ${props => props.margin || '0px'};
    padding: ${props => props.padding || '50px 5%'};
  }
  background: ${props => props.bgColor || 'white'};
`;

export const Card = styled.div`
  background-color: ${props => props.theme.bgColor || 'rgba(80, 150, 244, 0.1)'};
  border: ${props => props.theme.border || 'none'};
  padding: ${props => props.theme.paddingMobile || '50px 16px'};
  height: 810px;
  display: flex;
  justify-content: center;
  flex-flow: column;
  @media (min-width: 768px) {
    height: 498px;
    border-radius: 0px;
  }
  @media (min-width: 992px) {
    padding: ${props => props.theme.padding || '100px'};
    border-radius: 20px;
    max-width: 1130px;
    min-height: 520px;
  }
`;
export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const Gradient = css`
  line-height: 139px;
  width: fit-content;
  background-color: #5b26bf;
  background-image: linear-gradient(134deg, #5b26bf 0%, #5096f4 100%);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  padding-right: 5%;
`;

export const Vertical = css`
  writing-mode: vertical-lr;
  position: absolute;
  right: -85px;
  width: fit-content !important;
  top: -10%;

  @media (max-width: 1200px) {
    right: inherit;
    font-size: 180px;
  }
`;
const Heading = css`
  font-family: ${props => props.font || 'Kollektif'};

  &,
  & strong,
  div[class*='strong'],
  p[class*='strong'],
  span[class*='strong'] {
    font-size: ${props => props.desktopsize || '82px'};
    font-weight: ${props => props.weight || '700'};
    color: ${props => props.color || '#222222'};
    width: 100%;
    text-align: ${props => props.align || 'left'};
    line-height: ${props => props.lineheightdesktop || '1.1'};
    letter-spacing: -0.01em;
    margin-bottom: 0px;
    ${props =>
      props.type === 'shadow'
        ? 'text-shadow: 0px 0px 36px rgba(60, 67, 86, 0.3), 0px 8px 16px rgba(91, 38, 191, 0.4);'
        : ''}
  }

  & strong,
  div[class*='strong'],
  p[class*='strong'],
  span[class*='strong'] {
    font-family: Kollektif;
    font-weight: 700;
  }

  @media (min-width: 768px) and (max-width: 992px) {
    &,
    & strong,
    div[class*='strong'],
    p[class*='strong'],
    span[class*='strong'] {
      font-size: ${props => props.tabletsize || '48px'};
      line-height: ${props => props.lineheighttablet || '1.1'};
    }
  }

  @media (max-width: 767px) {
    &,
    & strong,
    div[class*='strong'],
    p[class*='strong'],
    span[class*='strong'] {
      font-size: ${props => props.mobilesize || '36px'};
      line-height: ${props => props.lineheightmobile || '1.1'};
    }
  }
  &::after,
  & strong::after,
  div[class*='strong']::after,
  p[class*='strong']::after,
  span[class*='strong']::after {
    content: ' ';
  }

  ${props => (props.variant === Variant.Gradient ? Gradient : '')}

  ${props => (props.variant === Variant.Vertical ? Vertical : '')}
`;

const HeadingTag = ({ heading, ...otherProps }: CustomITitle) => {
  const Tag = heading || 'p';

  return <Tag {...otherProps} />;
};

export const Title = styled(HeadingTag)`
  ${Heading}
`;

export const TitleHighlighted = styled.span`
  font-family: ${props => props.font || 'Kollektif'};
  font-size: ${props => props.desktopsize || '100px'};
  font-weight: ${props => props.weight || '700'};
  color: ${props => props.color || '#3c4356'};
  width: 100%;
  text-align: ${props => props.align || 'left'};
  margin-bottom: 0px;
  @media (max-width: 768px) {
    font-size: ${props => props.mobilesize || '52px'};
  }
  &::after {
    content: ' ';
  }
`;

export const Text = styled.p`
  font-family: ${props => props.font || 'Kollektif'};
  font-size: ${props => props.size || '14px'};
  font-weight: ${props => props.weight || '400'};
  line-height: ${props => props.lineheight || '20px'};
  color: ${props => props.color || '#3c4356'};
  text-align: ${props => props.align || 'left'};
  letter-spacing: -0.01em;
`;
const TitleOutlineStyled = styled.p`
  position: relative;
  display: inline;
  width: 150%;
  & label {
    font-family: Kollektif;
    font-size: 82px;
    font-weight: bold;
    position: relative;
    left: 0;
    color: #5096f4;
    display: inline;
    white-space: nowrap;
    z-index: -1;
    opacity: 0.1;
    line-height: 98px;
    text-align: center;
    letter-spacing: -0.01em;
    margin: 0px 50px;
  }
  @media (max-width: 768px) {
    font-size: 72px !important;
  }
  @media (max-width: 992px) {
    font-size: 102px;
  }
`;
interface ITitleOutline {
  text: string | Array<string>;
  count?: number;
}
export const TitleOutline = ({ text, count = 3 }: ITitleOutline) => {
  const outroRef = useRef<HTMLDivElement>();

  const [nText, setNText] = useState(count);

  useEffect(() => {
    const section = outroRef.current;
    const w = document.querySelector('body');
    if (section.offsetWidth < w.scrollWidth) {
      setNText(nText + 2);
    }
  }, [outroRef.current]);

  return (
    <TitleOutlineStyled ref={outroRef} data-value={text}>
      {Array.from(Array(nText)).map((x, index) => (
        <React.Fragment key={index}>
          {typeof text === 'string' && <label> {text}</label>}
          {Array.isArray(text) &&
            text.map((txt, i) => <label key={`txt-${i}-${index}`}>{txt}</label>)}
        </React.Fragment>
      ))}
    </TitleOutlineStyled>
  );
};

export const List = styled.ul`
  font-family: ${props => props.font || 'Kollektif'};
  padding-left: ${props => props.paddingLeft || '22px'};
  padding-top: ${props => props.paddingTop || '15px'};
  color: ${props => props.color || '#3C4356'};
  margin-left: ${props => props.marginLeft || '1.45rem'};
  li {
    font-size: ${props => props.font || '16px'};
    margin-bottom: 0px;
  }
  &.list-dashed {
    list-style-type: none;
    list-style: none;
    margin: 0;
  }
  &.list-dashed > li {
    line-height: 19px;
    font-style: normal;
    font-weight: normal;
    padding: 7px 0px;
  }
  li::before {
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
  &.list-dashed li::before {
    margin-right: 0.3em;
    text-indent: -5px;
    content: '';
  }
  li::marker {
    color: ${props => props.markerColor || '#5b26bf'};
    font-size: 1.5rem;
  }
  &.list-dashed li::marker {
    color: #3c4356;
    font-size: 1.2rem;
    content: '—';
    margin-right: 1em;
  }
  @media (max-width: 768px) {
    li {
      font-size: ${props => props.mobileFont || '14px'};
    }
  }
`;
export const RowStyled = styled(Row)`
  margin-top: 43px;
  padding-top: 40px;
  overflow: hidden;

  .row-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .first-container {
    max-width: 430px;
    margin-right: 15px;
  }
  @media (max-width: 768px) {
    padding-top: 0px !important;
    .first-container {
      max-width: 100% !important;
      margin-right: 0px !important;
    }
  }
`;
export const SectionHeader = styled.div`
  background: linear-gradient(134deg, #5b26bf 0%, #5096f4 100%);
  position: relative;
  z-index: 0;
  height: 100%;
  width: 100%;
`;

export const SectionHeaderImage = styled.div`
  z-index: -1;
`;

export const SectionHeaderTitle = styled.div`
  background: transparent;
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LettersAnimation = ({ children }) => {
  const outroRef = useRef<HTMLDivElement>();
  const outroContentRef = useRef<HTMLDivElement>();
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const section = outroContentRef.current;
    const w = outroRef.current;
    const defaultWidth = 1000;
    const dif = w.scrollWidth - section.offsetWidth;
    const [x, xEnd] = ['1%', (dif < defaultWidth ? dif : defaultWidth) * -0.3];
    gsap.fromTo(
      w,
      { x },
      {
        x: xEnd,
        scrollTrigger: {
          trigger: section,
          scrub: 1,
        },
      },
    );
  }, []);
  return (
    <div ref={outroContentRef}>
      <div ref={outroRef}>{children}</div>
    </div>
  );
};
interface IHeaderAnimation {
  cookieKey: string;
  children: React.ReactNode;
}
export const HeaderAnimation = ({ children, cookieKey }: IHeaderAnimation) => {
  const [active, setActive] = useState(false);
  const { scrollPosition } = usePosition();
  const [showLoader, setShowLoder] = useState(false);
  const date = new Date();
  const expires = new Date(date.setDate(date.getDate() + 1));
  const [item, setValue] = useCookie(cookieKey, { expires });
  useEffect(() => {
    if (!active && scrollPosition > 0) {
      setActive(!active);
    }
  }, [active, scrollPosition]);

  useEffect(() => {
    if (!item) {
      setValue('true');
      setShowLoder(true);
    }
  }, []);
  if (showLoader) {
    return <div className={`${active ? 'slideInUp' : 'translate'}`}>{children}</div>;
  }
  return <div className={`origin`}>{children}</div>;
};
export const Loader = styled.div`
  align-items: center;
  background: linear-gradient(134deg, #5b26bf 0%, #5096f4 100%);
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1002;
  width: 100%;
  height: 100vh;

  .loader-animation {
    height: 300px;
    width: 300px;
    position: relative;
  }
`;
export const Preloader = () => {
  return (
    <>
      <Loader key={`loader`} id="___loader">
        <Player
          src="/animations/loader.json"
          className="loader-animation"
          autoplay={true}
          loop={true}
          renderer="html"
        />
      </Loader>
    </>
  );
};
export const WordContainer = styled.div`
  height: 60px;
  margin: 0;
  padding: 0;
  width: fit-content;
  position: relative;
  transform-style: preserve-3d;
  box-sizing: border-box;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-content: center;

  .front {
    transition: all 500ms linear;
    position: relative;
    backface-visibility: hidden;
  }

  .back {
    transition: all 500ms linear;
    position: relative;
    backface-visibility: hidden;
  }

  .back {
    transform: perspective(2000px) rotateX(180deg) translateY(-100%);
    transform-origin: 100% -50%;
  }
  .front.flip {
    transform: perspective(2000px) rotateX(-180deg);
  }
  .back.flip {
    transform: perspective(2000px) rotateX(0) translateY(-100%);
    transform-origin: 100% -50%;
  }
  .front.unflip {
    transform: perspective(2000px) rotateX(0deg);
  }
  .back.unflip {
    transform: perspective(2000px) rotateX(180) translateY(-100%);
    transform-origin: 100% -50%;
  }
  .back {
    width: fit-content;
    left: 0;
    right: 0;
    margin: auto;
  }
  .front {
    justify-self: center;
    align-self: center;
  }
`;
export const WordsOutlinedStyled = styled.h1`
  font-family: 'Kollektif';
  font-size: 4vw;
  position: relative;
  width: fit-content;
  white-space: wrap;
  line-height: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;

  /* border and color of word */
  color: black;
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;

  @media (min-width: 768px) {
    font-size: 5vw;
  }

  // @media (min-width: 800px) {
  //   font-size: 5vw;
  // }

  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    font-size: 5vw;
  }

  // XX-Large devices (larger desktops, 1650px and up)
  @media (min-width: 1650px) {
    font-size: 90px;
  }
`;

const CssButton = css`
  height: 48px;
  min-width: 210px;
  width: 210px;
  padding-top: 14px;
  padding-bottom: 15px;
  font-family: Kollektif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.01em;
  outline: 0;
  border: none;
  padding-left: 4px;
  padding-right: 4px;
  &.btn-purple {
    background: #5b26bf;
    box-shadow: 0px 4px 8px rgba(91, 38, 191, 0.45);
    color: #fff;
    border-radius: 24px;
  }
  &.btn-white {
    background: #fff;
    box-shadow: 0px 4px 8px rgba(91, 38, 191, 0.3);
    color: #222222;
    border-radius: 24px;
  }
  &.rounded {
    min-width: 48px;
    width: 48px;
    height: 48px;
    border-radius: 50% !important;
    padding: 0;
  }
  &:hover {
    box-shadow: 0px 12px 24px rgba(91, 38, 191, 0.45);
  }
  &:active,
  &:focus {
    box-shadow: 0px 2px 4px rgba(91, 38, 191, 0.45);
  }
  &.fit-content {
    width: fit-content;
  }
  @media (min-width: 360px) {
    font-size: 16px;
    padding-left: 24px;
    padding-right: 24px;
  }
  transition: all 200ms linear;
`;
export const RoundedButton = styled.button`
  ${CssButton}
  ${props => (props.disabled ? 'opacity: 0.45;' : '')}
`;
export const ButtonLink: typeof TLink = styled(TLink)`
  ${CssButton}
  text-decoration: none;
`;
