import React, { useEffect, createRef, useState, useRef } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';

interface ILottieAnimationProps {
  src: string;
  style?: any;
  loop?: any;
  autoplay?: boolean;
  name?: string;
  renderer?: any;
  onHover?: boolean;
  className?: string;
}

const LottieAnimation = ({
  src,
  style,
  className,
  loop,
  autoplay,
  name,
  renderer,
  onHover = false,
}: ILottieAnimationProps) => {
  let lottie = createRef<Player>();
  const isMountedRef = useRef(null);
  const [frame, setFrame] = useState(0);
  const frameToStop = 80;
  const hover = async e => {
    e.preventDefault();
    lottie.current.play();
  };
  const leave = e => {
    e.preventDefault();
    lottie.current.stop();
    setFrame(0);
  };
  const stopLottie = () => {
    lottie.current.stop();
    setFrame(0);
    lottie.current.setSeeker(frame);
  };
  useEffect(() => {
    isMountedRef.current = true;
    if (isMountedRef.current && frame === frameToStop && onHover) {
      stopLottie();
    }
    return () => {
      isMountedRef.current = false;
    };
  });
  return (
    <>
      <div {...(onHover && { onMouseEnter: hover, onMouseLeave: leave })}>
        <Player
          autoplay={autoplay}
          loop={loop}
          src={src}
          style={style}
          className={className}
          renderer={renderer}
          ref={lottie}
          id={name}
          hover={onHover}
          onEvent={event => {
            if (isMountedRef.current && event === 'frame') setFrame(frame + 1);
          }}
        />
      </div>
    </>
  );
};
export default LottieAnimation;
