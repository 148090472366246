import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import TLink from './TLink';
import SVGImage from './SVGImage';
import { StateContext } from '../providers/stateProvider';
import { Text } from '../components/components';

interface IMobileMenuProps {
  menuLinks: Array<any>;
}
const Overlay = styled.div`
  position: fixed;
  background: #222222;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  overflow: hidden;
  z-index: 1000;
  &.open {
    visibility: visible;
    opacity: 1;
  }
  &.open li {
    animation: fadeInRight 0.5s ease forwards;
    animation-delay: 0.35s;
  }
  &.open li:nth-of-type(2) {
    animation-delay: 0.4s;
  }
  &.open li:nth-of-type(3) {
    animation-delay: 0.45s;
  }
  &.open li:nth-of-type(4) {
    animation-delay: 0.5s;
  }

  nav {
    font-size: 50px;
    font-family: 'Kollektif';
    font-weight: 400;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    position: relative;
    height: 100%;
  }
  ul li {
    min-height: 50px;
    position: relative;
    opacity: 0;
  }
  ul li a {
    display: block;
    position: relative;
    color: #fff;
    text-decoration: none;
    overflow: hidden;
  }
  ul li a:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0%;
    transform: translateX(-50%);
    height: 3px;
    background: #fff;
    transition: 0.35s;
  }
  .menu-mobile-links li {
    list-style: none;
  }
  .menu-mobile-links .menu-mobile-item {
    font-family: Kollektif;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 58px;
    letter-spacing: -0.01em;
    color: white;
    text-decoration: none;
  }
  @keyframes fadeInRight {
    0% {
      opacity: 0;
      left: 20%;
    }
    100% {
      opacity: 1;
      left: 0;
    }
  }
  .overlay-menu {
    margin-top: 36px;
  }
  .menu {
    height: 64px;
    display: flex;
    align-items: center;
  }
  .navbar {
    height: 64px;
    padding-bottom: 0;
    padding-top: 0;
  }
  .mobile-footer {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .copyright-text {
    margin-bottom: 24px;
    margin-top: 20px;
  }
  .mobile-footer-text {
    padding-bottom: 10px;
  }
  .mobile-footer-text p {
    margin-bottom: 0px;
  }
`;

const MobileMenu = ({ menuLinks }: IMobileMenuProps) => {
  const { open, changeOpenMenu } = useContext(StateContext);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.document.querySelector('html').style.overflow = open ? 'hidden' : 'auto';
    }
  }, [open]);

  return (
    <>
      <Overlay className={`d-md-none ${open ? 'open' : ''}`} id="overlay">
        <nav className="navbar gutt-16 h-100">
          <div className="w-100 h-100">
            <div className="navbar-container position-relative h-100">
              <div className="menu" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <SVGImage
                  src="full_logo_horizontal_white.svg"
                  alt="Envibe Studios"
                  height={24}
                  width={120}
                />

                <span onClick={changeOpenMenu} style={{ cursor: 'pointer' }}>
                  <SVGImage src="menu_close.svg" alt="Close" height={36} width={36} />
                </span>
              </div>
              <div className="overlay-menu ml-3">
                <ul className="menu-mobile-links">
                  {menuLinks.map(link => (
                    <li key={link.name} onClick={changeOpenMenu}>
                      <TLink
                        className="menu-mobile-item"
                        to={link.link}
                        activeClassName="gradient-text"
                        partiallyActive={link.link !== '/'}
                        title={link.name}
                      >
                        {link.name}
                      </TLink>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mobile-footer">
                <div className="mobile-footer-text">
                  <Text font="Kollektif" size="18px" color="#FFFFFF" weight="700" lineheight="22px">
                    Bring us your idea
                  </Text>
                  <Text font="Kollektif" size="18px" color="#FFFFFF" weight="400" lineheight="22px">
                    We’ll help you do the rest
                  </Text>
                </div>
                <div className="copyright-text">
                  <Text font="Kollektif" size="12px" color="#FFFFFF" weight="400" lineheight="14px">
                    © 2021 – Envibe Studios | All Rights Reserved
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </Overlay>
    </>
  );
};
export default MobileMenu;
