import { useState, useLayoutEffect } from 'react';
const isBrowser = typeof window !== 'undefined';
function usePosition() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const onScroll = () => {
    setScrollPosition(window.scrollY);
  };
  if (isBrowser) {
    useLayoutEffect(() => {
      window.addEventListener('scroll', onScroll);
      return () => window.removeEventListener('scroll', onScroll);
    }, [window.scrollY]);
  }

  return { scrollPosition };
}

export default usePosition;