import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

interface IImageProps {
  src: string;
  alt: string;
  style?: any;
  className?: any;
  id?: string;
  height: string | number;
  width: string | number;
  title?: string;
}

function renderSVGImage(file, other) {
  return <img src={file.node.publicURL} {...other} />;
}

const SVGImage = function ({ src, ...other }: IImageProps) {
  return (
    <StaticQuery
      query={graphql`
        query {
          SVGImages: allFile(filter: { extension: { regex: "/svg/" } }) {
            edges {
              node {
                extension
                relativePath
                publicURL
              }
            }
          }
        }
      `}
      render={({ SVGImages }) =>
        renderSVGImage(
          SVGImages.edges.find(image => image.node.relativePath === src),
          other,
        )
      }
    />
  );
};
export default SVGImage;
