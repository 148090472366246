import React, { useContext } from 'react';
import SVGImage from '../components/SVGImage';
import styled from 'styled-components';
import LottieAnimation from '../components/lottieAnimation';
import TLink from './TLink';
import usePosition from '../components/usePosition';
import { ButtonLink } from './components';
import { StateContext } from '../providers/stateProvider';

interface IHeaderProps {
  menuLinks: Array<any>;
  navbarBackground: string;
  navbarColor: string;
}
const HeaderStyled = styled.header`
  height: 64px !important;

  width: 100% !important;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: ${props =>
    props.navbarBackground === 'transparent' &&
    props.scrollPosition > 0 &&
    props.scrollPosition < 730
      ? 'rgba(0, 0, 0, 0.3)'
      : props.navbarBackground};
  ${props => (props.scrollPosition > 0 ? 'backdrop-filter: blur(10px);' : '')}
  transition: all 200ms linear;
  display: list-item;

  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
  }
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
  }
  .navbar li {
    color: white;
    text-decoration: none;
    flex: 1;
    z-index: 100;
    padding-left: 16px;
  }
  .navbar-list li {
    list-style-type: none;
    padding: 1rem;
    z-index: 100;
  }
  .navbar-list li a {
    font-family: Kollektif;
    text-decoration: none;
    padding-bottom: 3px;
    font-size: 16px;
    font-weight: 700;
    position: relative;
  }

  .navbar-menu-button {
    border-style: none;
    background: none;
  }
  .navbar-collapse {
    height: 100vh;
    position: fixed;
    top: 50px;
    background: blue;
    left: 0px;
    right: 0px;
  }

  .bottom-animation:not(.current-page):after {
    display: block;
    content: '';
    border-bottom: solid 2px ${props => props.navbarColor};
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }
  .bottom-animation:hover:not(.current-page):after {
    transform: scaleX(1);
  }
  .bottom-animation:after {
    transform-origin: 0% 50%;
  }

  .current-page:after {
    display: block;
    content: '';
    border-bottom: solid 2px ${props => props.navbarColor};
  }
  .brand-logo {
    height: 42px;
    width: 200px;
  }
  .white-space-nowrap {
    white-space: nowrap;
  }
  .nav-color {
    color: ${props => props.navbarColor};
  }
  .navbar-button {
    padding: 10px 15px 9px 15px !important;
    font-size: 14px !important;
    height: 36px;
  }
  .transition {
    transition: all 200ms linear;
  }
  .navbar-mobile li {
    display: inline-block;
  }
`;

const Header = ({ menuLinks, navbarBackground, navbarColor }: IHeaderProps) => {
  const { changeOpenMenu } = useContext(StateContext);
  const buttonToggle = () => {
    return (
      <button
        onClick={e => {
          e.preventDefault();
          changeOpenMenu();
        }}
        className="navbar-menu-button"
      >
        {navbarColor === 'white' ? (
          <SVGImage src="menu_white.svg" alt="Menu" height={24} width={24} />
        ) : (
          <SVGImage src="menu_purple.svg" alt="Menu" height={24} width={24} />
        )}
      </button>
    );
  };
  const { scrollPosition } = usePosition();
  const getIconD = () => {
    return (
      <>
        <SVGImage
          src="full_logo_horizontal_white.svg"
          alt="Envibe Studios"
          height={37}
          width={180}
          className={`d-${navbarColor === 'white' ? 'block' : 'none'}`}
        />
        <SVGImage
          src="full_logo_horizontal_color.svg"
          alt="Envibe Studios"
          height={37}
          width={180}
          className={`d-${navbarColor !== 'white' ? 'block' : 'none'}`}
        />
      </>
    );
  };
  const getIconM = () => {
    return (
      <>
        <SVGImage
          src="full_logo_horizontal_white.svg"
          alt="Envibe Studios"
          height={24}
          width={120}
          className={`d-${navbarColor === 'white' ? 'block' : 'none'} d-md-none`}
        />
        <SVGImage
          src="full_logo_horizontal_color.svg"
          alt="Envibe Studios"
          height={24}
          width={120}
          className={`d-${navbarColor !== 'white' ? 'block' : 'none'} d-md-none`}
        />
      </>
    );
  };
  const logoBrand = () => {
    return (
      <TLink to="/" ariaLabel="Envibe Studios">
        {
          <>
            <span className="d-none d-md-flex">
              {scrollPosition < 730 && getIconD()}
              <LottieAnimation
                src="/animations/logo-mobile-menu-color.json"
                className={`brand-logo d-${
                  navbarColor !== 'white' && scrollPosition >= 730 ? 'block' : 'none'
                }`}
                name="menu-color"
                autoplay={false}
                onHover={true}
                loop={false}
                renderer="svg"
              />
              <LottieAnimation
                src="/animations/logo-mobile-menu-light.json"
                className={`brand-logo d-${
                  navbarColor === 'white' && scrollPosition >= 730 ? 'block' : 'none'
                }`}
                name="menu-color"
                autoplay={false}
                onHover={true}
                loop={false}
                renderer="svg"
              />
            </span>
            {getIconM()}
          </>
        }
      </TLink>
    );
  };

  const getTitle = lk => {
    let title = '';
    switch (lk) {
      case '/':
        title = 'Explore our home page';
        break;
      case '/about/':
        title = 'Discover our process';
        break;
      case '/services/':
        title = 'How we can provide';
        break;
      case '/projects/':
        title = 'Explore our projects';
        break;
    }
    return title;
  };

  return (
    <>
      <HeaderStyled
        navbarColor={navbarColor}
        navbarBackground={navbarBackground}
        scrollPosition={scrollPosition}
      >
        <nav className="navbar outt-40 gutt-16">
          <div className="w-100 my-auto">
            <div className="navbar-container position-relative">
              {logoBrand()}
              <div>
                <ul className="d-none d-md-flex navbar-list pb-0 mb-0 white-space-nowrap">
                  {menuLinks.map(link => (
                    <li key={link.name} className="pb-0">
                      {link.name === 'Contact Us' ? (
                        <ButtonLink
                          className={`floating-icon ${
                            navbarColor === 'white' ? 'btn-white' : 'btn-purple'
                          } navbar-button transition`}
                          to={link.link}
                          title={link.name}
                        >
                          {link.name}
                        </ButtonLink>
                      ) : (
                        <TLink
                          to={link.link}
                          activeClassName="current-page"
                          partiallyActive={link.link !== '/'}
                          className="nav-color bottom-animation"
                          title={getTitle(link.link)}
                        >
                          {link.name}
                        </TLink>
                      )}
                    </li>
                  ))}
                </ul>

                <ul className="d-md-none navbar-list pb-0 mb-0 white-space-nowrap navbar-mobile">
                  <li className="pb-0">
                    <ButtonLink
                      className={`floating-icon ${
                        navbarColor === 'white' ? 'btn-white' : 'btn-purple'
                      } navbar-button transition`}
                      to="/contacts/"
                      style={{ height: 36 }}
                      title="Contact us"
                    >
                      Contact Us
                    </ButtonLink>
                  </li>
                  <li className="px-0 py-0">{buttonToggle()}</li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </HeaderStyled>
    </>
  );
};
export default Header;
