import React from 'react';
import { GatsbyLinkProps } from 'gatsby';
import TransitionLink from 'gatsby-plugin-transition-link';
import gsap from 'gsap';
const TRANSITION_DELAY = 0.5;
const TRANSITION_LEN = 1.5;

const getAnimData = () => {
  // Returns the desired animation data based on the nav and page numbers

  return {
    dir: 'left',
  };
};
const getAnim = animData => {
  const seconds = TRANSITION_LEN;
  const directionFrom = animData.dir === 'left' ? '100%' : '-100%';
  return gsap
    .timeline()
    .set(`.layoutLoader`, {
      display: 'flex',
      zIndex: 999,
    })
    .set(`#loader-layout`, { opacity: 1, zIndex: 9999 })
    .set(`.layoutBgGradient`, {
      x: 0,
      display: 'flex',
      skewX: 0,
      zIndex: 999,
      opacity: 1,
    })
    .to(`.layoutLoader`, {
      duration: 0.8,
      opacity: 0,
      display: 'none',
    })
    .to(`.layoutBgGradient`, {
      x: directionFrom,
      skewX: -2,
      opacity: 0,
      ease: 'power1.easeInOut',
      duration: seconds / 4,
    });
};
const exitHorizontal = ({ length }, direction, entry) => {
  const seconds = length;
  const animData = getAnimData();
  entry.state = { animData };
  const directionFrom = direction === 'left' ? '100%' : '-100%';
  return gsap
    .timeline()
    .set(`.layoutBgGradient`, {
      x: directionFrom,
      display: 'flex',
      skewX: -2,
      zIndex: 999,
      opacity: 0,
    })
    .set(`#loader-layout`, { opacity: 0, zIndex: 9999 })
    .to(`.layoutBgGradient`, {
      x: 0,
      skewX: 0,
      opacity: 1,
      ease: 'power1.easeInOut',
      duration: seconds / 5,
    })
    .set(`.layoutLoader`, {
      opacity: 1,
      display: 'flex',
      zIndex: 999,
    })
    .to(`#loader-layout`, { opacity: 1, zIndex: 9999, duration: 0.1 });
};

type CustomGatsbyLinkProps = Omit<GatsbyLinkProps<{}>, 'ref'> & { ariaLabel?: any };
const TLink = ({
  children,
  to,
  activeClassName,
  className,
  style,
  activeStyle,
  partiallyActive,
  ariaLabel,
  title,
}: CustomGatsbyLinkProps) => {
  const internal = /^\/(?!\/)/.test(to);
  if (internal) {
    return (
      <TransitionLink
        to={to}
        activeStyle={activeStyle}
        className={className}
        aria-label={ariaLabel}
        style={style}
        title={title}
        activeClassName={activeClassName}
        exit={{
          length: TRANSITION_LEN,
          trigger: ({ exit, entry }) => exitHorizontal(exit, 'right', entry),
        }}
        entry={{
          delay: TRANSITION_DELAY,
          length: 0.1,
          trigger: ({ entry }) => {
            const animData = entry.state?.animData || getAnimData();
            return getAnim(animData);
          },
        }}
        partiallyActive={partiallyActive}
      >
        {children}
      </TransitionLink>
    );
  }
  return (
    <a href={to} target="_blank" className={className} style={style} title={title}>
      {children}
    </a>
  );
};
export default TLink;
