import React from 'react';
import SVGImage from '../components/SVGImage';
import { Text, List, Row, RoundedButton, ButtonLink } from '../components/components';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import TLink from './TLink';
const FooterContainer = styled.footer`
  background: ${props => props.bgColor || 'white'};
`;
const FooterStyled = styled.div`
  background: #111111;
  color: #ffffff;

  width: 100%;
  padding-top: 50px;
  line-height: 19.2px;
  @media (min-width: 768px) {
    height: 450px;
  }

  .footer-content {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .footer-text {
    margin-top: 24px;
  }
  .footer-text > p {
    margin-bottom: 0px;
  }

  .copyright-text p {
    font-family: 'Kollektif';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: #ffffff;
    padding-top: 80px;
    margin-bottom: 80px;
  }
  .footer-btn {
    margin-top: 38px;
  }
  .footer-animation {
    height: 100%;
    display: block;
  }
  .footer-btn > .btn-purple {
    font-size: 11px;
  }
  // @media (min-width: 768px) and (max-width: 796px) {
  //   .footer-btn > .btn-purple {
  //     font-size: 11px;
  //   }
  // }
  // @media (min-width: 797px) and (max-width: 845px) {
  //   .footer-btn > .btn-purple {
  //     font-size: 12px;
  //   }
  // }
  // @media (min-width: 846px) and (max-width: 895px) {
  //   .footer-btn > .btn-purple {
  //     font-size: 13px;
  //   }
  // }
  // @media (min-width: 895px) and (max-width: 991px) {
  //   .footer-btn > .btn-purple {
  //     font-size: 14px;
  //   }
  // }
  @media (min-width: 576px) {
    .footer-btn > .btn-purple {
      font-size: 11px;
    }
  }
  @media (min-width: 768px) {
    .footer-btn > .btn-purple {
      font-size: 12px;
    }
  }
  @media (min-width: 992px) {
    .footer-btn > .btn-purple {
      font-size: 16px;
    }
  }

  .siteLinks {
    list-style: none;
    padding: 0px;
    margin: 0px;
  }

  .siteLinks li {
    padding-bottom: 40px;
  }

  .siteLinks li a {
    font-family: 'Kollektif';
    text-decoration: none;
    padding-bottom: 3px;
    color: white;
    font-size: 14px;
    position: absolute;
  }

  .siteLinks li a:not(.footer-not-active-link):after {
    display: block;
    content: '';
    border-bottom: solid 2px white;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }
  .siteLinks li a:hover:not(.footer-not-active-link):after {
    transform: scaleX(1);
  }
  .siteLinks li a:after {
    transform-origin: 0% 50%;
  }

  // .footer-not-active-link:after {
  //   display: block;
  //   content: '';
  //   border-bottom: solid 2px white;
  // }

  ul.siteLinks > li:first-child {
    padding-top: 0px !important;
  }

  .footer-active-link {
    border-bottom: 2px solid;
    color: white;
  }

  .floating-icon {
    right: 46px;
    z-index: 1;
    top: -24px;
    width: 48px;
    height: 48px;
    // background-image: linear-gradient(134deg, #5b26bf 0%, #5096f4 100%);
    background-color: #5b26bf;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    float: right;
    transform: translate(-16px, -74px);
    cursor: pointer;
    align-items: center;
  }
  .floating-icon:after {
    position: absolute;
    width: 48px;
    height: 48px;
    content: '';
    border-radius: 50%;
    opacity: 0;
    // animation: wave-animate 3s 1.5s infinite ease-out;
  }
  @keyframes wave-animate {
    0% {
      transform: scale(0);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
      transform-origin: center;
      background-image: linear-gradient(134deg, #5096f4 0%, #5b26bf 100%);
    }
  }

  @media (min-width: 576px) {
    .floating-icon {
      transform: translate(-46px, -74px);
    }
  }
  @media (min-width: 768px) {
    .floating-icon {
      transform: translate(-79px, -74px);
    }
    // margin-top: 50px;
  }
  @media (min-width: 992px) {
    .floating-icon {
      transform: translate(-125px, -74px);
    }
  }
  .hr-line-bg {
    background: #777;
  }
`;

interface IFooterProps {
  menuLinks: Array<any>;
  socialMediaLinks: Array<any>;
  layoutBackground?: string;
}

const Footer = ({ menuLinks, socialMediaLinks, layoutBackground }: IFooterProps) => {
  return (
    <FooterContainer bgColor={layoutBackground}>
      <FooterStyled>
        <Fade bottom>
          <div className="footer-animation">
            <RoundedButton
              className="floating-icon rounded btn-purple"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              title="Scroll to top"
            >
              <SVGImage
                src="icon-arrow-to-top.svg"
                alt="Arrow up icon"
                className="m-2"
                height={7}
                width={12}
              />
            </RoundedButton>
            <div className="col-12 footer-content">
              <Row className="w-100">
                <div className="col-10 offset-2 col-md-3 offset-md-2">
                  <div className="">
                    <SVGImage
                      src="logo-footer-white.svg"
                      alt="Envibe Studios"
                      height={41}
                      width={192}
                    />
                  </div>
                  <div className="footer-text">
                    <Text font="Kollektif" size="16px" color="#FFFFFF" weight="700">
                      Bring us your idea
                    </Text>
                    <Text font="Kollektif" size="16px" color="#FFFFFF" weight="400">
                      We’ll help you do the rest
                    </Text>
                  </div>
                  <div className="footer-btn d-flex justify-content-start">
                    <ButtonLink
                      to="/contacts/"
                      className="btn-purple fit-content"
                      title="Contact us"
                    >
                      Let’s talk about your project!
                    </ButtonLink>
                  </div>
                </div>

                <div className="col-10 offset-2 col-md-2 offset-md-3 mt-5 mt-md-0">
                  <Row>
                    <div className="col-6">
                      <div className="col-12">
                        <List className="siteLinks">
                          {menuLinks.map(
                            link =>
                              link.link != '/contacts/' && (
                                <li key={link.name}>
                                  <TLink
                                    className="footer-link"
                                    to={link.link}
                                    // activeClassName="footer-not-active-link"
                                    partiallyActive={link.link !== '/'}
                                    title={link.name}
                                  >
                                    {link.name}
                                  </TLink>
                                </li>
                              ),
                          )}
                          <li key="Privacy">
                            <TLink
                              to="/privacy/"
                              className="footer-link"
                              title="Privacy"
                              // activeClassName="footer-not-active-link"
                            >
                              Privacy
                            </TLink>
                          </li>
                        </List>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="col-12">
                        <List className="siteLinks">
                          <li key="Contact Us">
                            <TLink
                              to="/contacts/"
                              className="footer-link"
                              title="Contact us"
                              // activeClassName="footer-not-active-link"
                            >
                              Contact Us
                            </TLink>
                          </li>
                          {socialMediaLinks.map(link => (
                            <li key={link.name}>
                              <TLink
                                to={link.link}
                                className="footer-link"
                                title="LinkedIn link"
                                // activeClassName="footer-not-active-link"
                              >
                                {link.name}
                              </TLink>
                            </li>
                          ))}
                        </List>
                      </div>
                    </div>
                  </Row>
                </div>
              </Row>
              <div className="copyright-text offset-md-2 offset-2">
                <p>© 2021 – Envibe Studios | All Rights Reserved</p>
              </div>
            </div>
          </div>
        </Fade>
      </FooterStyled>
    </FooterContainer>
  );
};
export default Footer;
