/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { ReactNode, useState, useEffect, useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import Header from './header';
import Footer from './footer';
import '../styles/layout.css';
import { TransitionPortal } from 'gatsby-plugin-transition-link';
import CookieConsent from 'react-cookie-consent';
import '../styles/cookiePopUp.css';
import TLink from '../components/TLink';
import SVGImage from '../components/SVGImage';
import { Preloader } from '../components/components';
import useCookie from './useCookie';
import styled from 'styled-components';
import { StateContext } from '../providers/stateProvider';
import { ContactDialog } from './ContactDialog';
import BurgerMenu from './menuMobile';
const Main = styled.main`
  background: ${props => props.bgColor || 'white'};
`;

interface ILayoutProps {
  children: ReactNode;
  navbarBackground?: string;
  navbarColor?: string;
  className?: string;
  layoutBackground?: string;
}

const Layout = ({
  children,
  navbarBackground = 'transparent',
  navbarColor = 'white',
  className = '',
  layoutBackground = 'white',
}: ILayoutProps) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          keywords
          description
          menuLinks {
            name
            link
          }
          socialMediaLinks {
            name
            link
          }
        }
      }
    }
  `);
  const [showLoader, setShowLoder] = useState(false);
  const date = new Date();
  const expires = new Date(date.setDate(date.getDate() + 1));
  const [item, setValue] = useCookie('loader', { expires });
  const { display, changeVisibility, open } = useContext(StateContext);

  useEffect(() => {
    if (!item) {
      setValue('true');
      setShowLoder(true);
    }
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLoder(false);
    }, 1500);
    return () => clearTimeout(timeout);
  }, []);

  if (showLoader) {
    return <Preloader />;
  }
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{data.site.siteMetadata.title}</title>
        <meta name="description" content={data.site.siteMetadata.description} />
        <meta name="keywords" content={data.site.siteMetadata.keywords} />
      </Helmet>
      {typeof item !== 'undefined' && item === 'true' && (
        <>
          <Header
            menuLinks={data.site.siteMetadata.menuLinks}
            navbarBackground={navbarBackground}
            navbarColor={navbarColor}
          />
          <BurgerMenu menuLinks={data.site.siteMetadata.menuLinks} />
          <div className="layout">
            <Main className={className} bgColor={layoutBackground}>
              {children}
            </Main>
          </div>
          <ContactDialog display={display} changeVisibility={changeVisibility} />
          <TransitionPortal level="top">
            <div className="layoutBgGradient"></div>
            <div className="layoutLoader">
              <SVGImage
                id="loader-layout"
                src="logo-mobile-menu-light.svg"
                alt="Envibe Studios logo"
                height={150}
                width={150}
              />
            </div>
            <div className="layoutBackground"></div>
          </TransitionPortal>
          <CookieConsent
            location="none"
            buttonText="Accept"
            disableStyles={true}
            containerClasses="cookie-popup"
            buttonClasses="cookie-popup-btn accept-btn"
            enableDeclineButton={false}
            cookieName="gatsby-gdpr-google-analytics"
            expires={150}
          >
            This website uses cookies to ensure you get the best experience
            {
              <TLink to="/cookie-policy/" className="cookie-popup-btn view-cookie-policy-btn">
                View Cookie Policy
              </TLink>
            }
          </CookieConsent>
          <Footer
            layoutBackground={layoutBackground}
            menuLinks={data.site.siteMetadata.menuLinks}
            socialMediaLinks={data.site.siteMetadata.socialMediaLinks} //social media links prop
          />
        </>
      )}
    </>
  );
};

export default Layout;
