import React, { useEffect } from 'react';
import { RoundedButton } from '../components/components';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  text-align: center;
  z-index: 1000;
  display: ${props => (props.display === 'true' ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;

  .centered {
    padding: 36px;
    border-radius: 5px;
    display: inline-block;
    vertical-align: middle;
    height: 237px;
    max-width: 443px;
    width: 443px;
    margin: 10px;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .title {
    max-width: 214px;
    font-family: Kollektif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #000000;
  }

  .text {
    font-family: Kollektif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.01em;
    max-width: 214px;

    color: #000000;
  }
`;
interface IDialogProps {
  display: boolean;
  changeVisibility: () => void;
}
export const ContactDialog = ({ display = false, changeVisibility }: IDialogProps) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.document.querySelector('html').style.overflow = display ? 'hidden' : 'auto';
    }
  }, [display]);
  return (
    <>
      <Container display={`${display}`}>
        <Fade>
          <div className="centered">
            <p className="title">Your message has been sent</p>
            <p className="text">A memeber of our team will contact you in the next 24h.</p>

            <RoundedButton
              className="btn-purple"
              onClick={e => {
                e.preventDefault();
                changeVisibility();
              }}
            >
              Ok
            </RoundedButton>
          </div>
        </Fade>
      </Container>
    </>
  );
};
